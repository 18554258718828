
.user-media-gallery > .media-gallery > div .media-item {
    width: 128px;
    height: 128px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.user-video video {
    border-radius: 16px;
}

.user-image > img {
    border-radius: 16px;
}

.pi-modal-content.user-media-dialog {
    border-radius: 16px;
}