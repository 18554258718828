.filter-panel {
    display: flex;
    gap: 12px;
    justify-content: start;
    align-items: center;
}

.large-list > *,
.large-list > * > *,
.large-list .filter-panel,
.large-list .filter-panel > span,
.large-list .filter-panel > span > input[type="text"] {
    width: 100%;
}

.large-list .filter-panel {
    display: block;
    margin-bottom: 24px;
}

