.video-player { 
}

.video-player > .video-container {
    min-width: 128px;
    min-height: 128px;
    display: inline-block;
}

.video-player.media-details > .video-container {
    width: 100%;
}

.video-player.media-details > .video-container iframe {
    width: 100%;
    border-radius: 24px;
}
