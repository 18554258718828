.metric-panels {
    gap: 12px;
    display: flex;
    justify-content: space-between;
}

.metric-panels > * { 
    flex-grow: 1;
    display: block;
    border-radius: 8px;
    background-color: black;
    text-align: center;
    padding: 12px;
    color: white;
    text-decoration: none;
}

.metric-panels > a:hover {
    text-decoration: none;
    color: #FFFFAA;
}

.metric-panels > * > span {
    font-size: 48px;
    display: block;
}

.metric-panels > * > label {
    font-size: 13px;
    display: block;
    padding-top: 8px;
}

