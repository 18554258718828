.system-error {
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    cursor: pointer;
}

.system-error:hover {
    color: black;
}

.system-error > * {
    width: 100%;
}

.system-error > div:first-child {
    
}

.system-error .message {
    width: 100%;
    display: block;
    background-color: #AAAAAA22;
    border-radius: 12px;
    padding: 16px;
    font-size: 12px;
}

.system-error .message > strong,
.system-error .message > span {
    display: block;

}

.system-error .message > span:first-child {
    padding-bottom: 12px;
}


.system-error .date {
    display: flex;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 12px;
    font-size: 13px;
    text-align: right;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.system-error .date > .ip {
    display: inline-block;
    min-width: 180px;
    text-align: left;
    margin-left: 8px;
}

.system-error .date > .error-user {
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin-right: 24px;
    display: flex;
    gap: 12px;
}

.system-error .date > *:first-child {
    margin-right: auto;
}

