.hover-container {
    position: relative;
    overflow: visible;
    width: 100%;
}

.hover-container > .hover-results {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    max-width: inherit;
    padding: 8px 12px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: calc(70vh - 200px);
    overflow-y: scroll;
    z-index: 5;
}

.hover-container > .hover-results:empty {
    display: none;
}

.hover-container > .hover-results > div {
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.hover-container > .hover-results > div:hover {
    background-color: #00000022;
}

.hover-container > .hover-results > div > div {
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 5px 0;
    border-bottom: #CCC 1px solid;
}

.hover-container > .hover-results > div:hover > div {
    border-bottom-color: transparent;
}

.hover-container > .hover-results > div > div > *:first-child {
    width: 64px;
}

.text-editable {
    cursor: pointer;
}

.text-editable:hover {
    background-color: #00000011;
}

.is-new-food {
    border: none !important;
    margin-bottom: 12px;
    padding: 8px;
    border-radius: 12px;
    background-color: #DDCFBB33;
}
