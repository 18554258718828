.def-button {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.def-button > span {
    min-width: 24px;
    display: inline-block;
    line-height: 0;
}

.def-button > span > label {
    --loader-size: 18px;
    width: var(--loader-size);
    height: var(--loader-size);
    min-height: var(--loader-size);
    min-width: var(--loader-size);
    
    box-sizing: border-box;
    display: inline-block;
    border-radius: 100%;
}

.def-button:disabled > span:last-child > label {
    border: white 3px solid;
    border-top-color: transparent;
    animation: spin 350ms linear infinite;
}

.def-button:disabled > span > label {
    
}

.def-button > span.body {
    min-width: unset;
}

.def-button > span:first-child {
    
}

@keyframes buttonSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
