.feedback {
    
}

.feedback * {
    text-decoration: none;
}

.delete-reply {
    display: inline-block;
    padding-left: 24px;
}

.delete-reply > a {
    font-size: 13px;
    color: maroon;
}

.thread:empty {
    margin-bottom: 24px;
}

.message-item {
    display: block;
    padding: 16px 0;
    border-bottom: var(--border);
    margin: 16px 0;
    opacity: 0.7;
    cursor: pointer;
    text-decoration: none;
    position: relative;
}

.message-item.details,
.message-item:hover {
    color: unset;
    opacity: 1.0;
    text-decoration: none;
}

.message-item.details {
    padding-top: 0;
}

.message-item p {
    margin-top: 0;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: #00000011;
    padding: 16px;
    min-height: 56px;
    box-sizing: border-box;
}

p.user-content.details,
.message-item.details p,
.message-item:hover p {
    color: black;
}

.message-item.un-read {
    font-weight: bold;
}

.message-item.un-read p {
    background-color: #99000022;
}

.message-item > p > span.delete,
.message-item > p > span.badge {
    float: right;
    padding: 5px 8px;
    font-size: 13px;
    border-radius: 5px;
}

.message-item > p > span.badge {
    /*display: inline-block;*/
    /*position: absolute;*/
    /*top: 28px;*/
    /*right: 16px;*/
    
    border: var(--border);
    color: white;
    background-color: black;
}

.message-item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-item-footer > *:first-child {
    display: block;
    margin-right: 8px;
}

.message-item-footer > *:last-child {
    margin-left: auto;
}

.message-item-footer .name {
    font-weight: bold;
    margin-right: 12px;
    font-size: 14px;
}

.message-item-footer .name a {
    text-decoration: none;
}

.message-item-footer .date-time-text {
    font-size: 14px;
}

.message-template > td {
    white-space: nowrap;
    padding-right: 12px;
    vertical-align: top        ;
}

.message-template>td:last-child {
    padding-right: 0;
    cursor: pointer;
}

.message-template>td:last-child:hover {
    background-color: #EEE;
    color: black;
}

.message-template .subject {
    font-size: 14px;
}

.message-template .preview {
    white-space: wrap;
    font-size: 13px;
}
