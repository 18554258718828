.social-panel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    flex-wrap: nowrap;
}

.social-panel > span {
    display: flex;
    gap: 8px;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.social-panel > span:hover {
    background-color: #00000011;
}

.social-panel > span > label {
    margin-right: 16px;
}

.social-panel > span > label:last-child {
    margin-right: 0;
}
