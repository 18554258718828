.loading-box {
    display: flex;
    margin: 32px 0;
    justify-content: flex-start;
    gap: 24px;
    align-items: center;
    align-content: center;
}

.loading-box > span:first-child {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #FFFFFF55;
    line-height: 0;
    box-sizing: border-box;
}

.loading-box > span:first-child > span:first-child {
    display: inline-block;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    box-sizing: border-box;
    border: red 4px solid;
    border-top-color: white;
    border-radius: 50%;
    animation: spin 500ms linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
