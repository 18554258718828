.workout-menu-image { 
    width: 120px;
    object-fit: contain;
    border-radius: 12px;
}

table.exercises-table {
    width: 100%;
    border-collapse: collapse;
}

table.exercises-table td {
    padding: 0;
    border-bottom: var(--border);
}

table.exercises-table td > h3 {
    margin: 10px 0;
    display: block;
}

table.exercises-table td > p {
    padding: 0 0 24px 0;
    margin: 0;
    display: block;
}

.exercises-table td.image {
    /* */
}

.exercises-table td.image img {
    border-radius: 8px;
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin-right: 24px;
}

.exercises-table tr.clickable * {
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
    transition-property: transform;
}

.exercises-table tr.clickable:hover .initials,
.exercises-table tr.clickable:hover img {
    transform: scale(1.2);
}

.exercises-table tr > *:first-child {
    width: 90px;
}

.exercises-list {
    position: relative;
}

.exercises-list tr.clickable:hover h3 {
    color: black;
}

.sub-workouts > h3 {
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: var(--border);
}

.sub-workouts div.workouts {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: start;
}

.sub-workouts div.workouts > div,
.sub-workouts div.workouts > a {
    max-width: 320px;
    cursor: pointer;
    display: block;
    background-color: var(--text-color);
    color: white;

    border: var(--border);
    border-radius: 8px;
    padding: 12px;
}

.sub-workouts div.workouts > div > h3 {
    font-size: 14px;
}

.sub-workouts div.workouts > div > p {
    padding-top: 8px;
    margin-bottom: 3px;
    margin-top: 0;
}

.sub-workouts div.workouts > a:hover {
    background-color: black;
    text-decoration: none;
}

div.active > .exercises-list > div.exercises {
    position: absolute;
    width: inherit;
    background-color: #FFF;
    max-height: 0;
    min-height: 0;
    opacity: 0.4;
    height: 0;
    border-radius: 16px;
    overflow-y: scroll;
    box-sizing: border-box;
    border-width: 0;
    margin: 0;
    transition-property: max-height, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
}

div.active > .exercises-list > div.exercises > div {
    padding: 16px;
}

div.active > .exercises-list > div.exercises.focused {
    opacity: 1.0;
    max-height: 60vh;
    height: 60vh;
}

.exercise-action-button {
    cursor: pointer;
}