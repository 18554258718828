.document-item {
    border-radius: 12px;
    background-color: #00000022;
    padding: 16px;
    margin-bottom: 16px;
}

.document-item h3 {
    margin: 0 0 12px 0;
}

.document-item ul > li,
.document-item ul {
    list-style: none;
    padding: 0;
    display: block;
}

.document-item ul > li {
    padding-bottom: 8px;
}

.document-item ul > li:last-child {
    padding-bottom: 0;
}


