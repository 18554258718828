.chart {
    max-width: 800px;
}

.chart > .chart-body {
    overflow-x: auto;
    padding: 12px;
    background-color: #AAAAAA33;
    border-radius: 12px;
    box-sizing: border-box;
}

.chart > .chart-body > h3 {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: var(--border);
}

.chart .chart-header {
    padding: 12px;
    font-size: 16px;
    border-bottom: var(--border);
    margin-bottom: 12px;
}

.chart > .chart-body > .date-selector {
    margin-bottom: 16px;
}

.chart > .chart-body > .date-selector input[type="date"] {
    font-size: 13px;
}
