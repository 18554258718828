:root {
  --border-color: #CCC;
  --border: var(--border-color) 1px solid;
  --def-red: rgb(238, 53, 36);
  --link-color: black;
  --link-hover-color: var(--def-red);
  --button-background-color: var(--def-red);
  --button-text-color: white;
  --background-color: #EEE;
  --text-color: #555;
  --notes-color: #630;
  --error-text-color: maroon;
}

* {
  box-sizing: border-box;
}

body, html {
  padding: 0;
  margin: 0 !important;
  box-sizing: border-box;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--background-color);
}

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--link-color);
}

a:hover {
  text-decoration: underline;
  color: var(--link-hover-color);
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

h4 {
  margin-bottom: 12px;
}

#content > h2 { 
  margin-bottom: 16px;
}

.filter-header { 
  margin-bottom: 24px;
}

strong {
  color: black;
}

button {
  cursor: pointer;
  padding: 10px 14px;
  font-size: 14px;
  border-radius: 5px;
  border-width: 0;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}

button:disabled, button.disabled {
  background-color: #CCC;
  color: white;
  user-input: none;
  user-select: none;
}

#root {
  display: block;
  width: 100vw;
}

#body, .column-content {
  display: flex;
  justify-content: stretch;
}

#body, .column-content > .content {
  flex-grow: 4;
}

.column-content > .content p {
  line-height: 1.5;
}

.pre-article-push {
  margin-bottom: 12px;
  margin-top: 12px;
}

.pre-article-push > strong {
  color: var(--button-background-color);
}

.pre-article-push:empty {
  display: none;
  
}

#body > div {
}

#body > div:last-child {
  flex-grow: 4;
}

h1.main-title {
  display: block;
  position: fixed;
  padding: 24px 0 24px 24px;
  background-color: var(--background-color);
  width: 100%;
  border-bottom: var(--border);
  z-index: 12;
}

.small-icons-row,
.form-item .div > span.small-icons-row {
  justify-content: space-evenly;
  padding-top: 16px;
  margin-bottom: 12px;
  display: flex;
  width: 100%;
  gap: 20px;
}

.small-icons-row > a {
  display: inline-block;
  padding: 5px 7px;
  border-radius: 5px;
  border: #CCC 1px solid;
}

.icon-upload {
  padding: 2px !important;
}

.form-item .div > span.small-icons-row.right {
  justify-content: flex-end;
}

div.none {
  border: none;
}

div.none .drop-target > * {
  padding: 0 !important;
}

div.json-direction {
  border-radius: 0;
}

#main-content {
  position: relative;
  width: max-content;
  display: block;
}

#main-column {
  width: 200px;
  min-width: 200px;
  min-height: 100vh;
  text-align: center;
  position: relative;
  border-right: #DDD 1px solid;
}

#main-column > div {
  min-height: 100vh;
  width: 200px;
  background-color: #FFF;
  position: fixed;
}

#main-content.content span#content,
#main-content.content div#content {
  padding: 108px 24px 24px 24px;
  min-width: 300px;
  height: 100px;
}

#main-column div > #logo {
  text-align: center;
}

#main-column div > #logo h1 {
  font-size: 64px;
  padding-top: 16px;
}

#main-column div > #logo h1 img { 
  width: 128px;
}

#main-menu {
  margin: 0 20px;
}

#main-menu ul {
  padding: 0;
  display: block;
}

#main-menu ul li {
  padding: 0;
  display: block;
  margin-bottom: 12px;
  text-align: left;
}

#main-menu ul.main li > a {
  display: flex;
  padding: 10px 12px;
  border-radius: 5px;
  background-color: #EEE;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

#main-menu ul.main li > a.with-icon {
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;

    line-height: 0;
    font-size: 0.9em !important;
}

#main-menu ul.main li.selected > a {
  background-color: black;
  color: white;
}

#main-menu ul.main li > a:hover {
  background-color: var(--link-hover-color);
  color: white;
  text-decoration: none;
}

#main-menu ul.small {
  margin-top: 24px;
}

#main-menu ul.small li > a {
  display: flex;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 12px;
}

#main-content {
  min-height: 100vh;
}

div#content {
  display: block;
  padding-top: 64px;
}

.pad > h2:first-child,
.def-header {
  border-bottom: #CCC 1px solid;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

input,
select,
textarea {
  border-radius: 4px;
  border: #CCC 1px solid;
  padding: 8px;
  font-size: 16px;
  font-family: inherit;
}

select.wide,
textarea.wide,
input.wide { 
  width: 100%;
  box-sizing: border-box;
}

textarea.tall,
input.tall {
  height: 120px;
  box-sizing: border-box;
}

div.image > label:last-child {
  font-size: 12px;
  display: block;
  padding-top: 4px;
}

.panel {
  display: block;
  border-radius: 16px;
  background-color: #AAAAAA55;
  padding: 24px;
}

.line-item-form {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 12px;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.cooking-direction {
  display: block;
  padding: 24px 0;
  border-radius: 12px;
  white-space: wrap;
  cursor: pointer;
  margin-bottom: 24px;
  border-bottom: #CCC 1px dashed;
}

p.text-editable {
  width: 100%;
}

.dropped.uploading img {
  opacity: 0.35;
}

.uploading, .uploading * { 
  
}

.drop-target.recipe-image-dropper {
  border: none !important;
}

.drop-target.recipe-image-dropper > * {
  border-color: transparent!important;
}

.drop-target.recipe-image-dropper > * {
  padding: 0!important;
}

.recipe-directions {
  width: 1024px;
}

.cooking-direction-image {
  max-width: 100%;
  border-radius: 12px;
}

.ingredient-list-selector {
  margin: 32px 0;
}

.ingredient-list-selector > div,
.ingredient-list-selector .filter-panel,
.ingredient-list-selector > div > div > span {
  width: 100%;
}

.ingredient-list-selector .search-filter {
  width: 100%;
}

.cooking-directions {
  font-size: 13px;
  padding: 8px;
  background-color: #FFFFFF55;
  white-space: wrap;
  display: inline-block;
  border-radius: 8px;
  cursor: pointer;
}

.cooking-directions:hover {
  background-color: #00000011;
}

.form-item .div {
  text-align: right;
  padding-left: 24px;
}

.form-item .div > span {
  display: block;
  padding-top: 24px;
}

.line-item {
  display: block;
  padding: 8px 0;
  border-bottom: #CCC 1px solid;
}

.line-item > .image-item {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  align-content: flex-start;
}

.line-item > .flex {
  display: flex;
  gap: 12px;
  align-items: center;
  align-content: center;
  width: 100%;
}

.line-item > .flex > *:last-child {
    margin-left: auto;
}

.line-item > .flex > .details {
  min-width: 50%;
}

.line-item > .flex > * {
  border: red 0 solid;
}

.line-item > .flex > .details > span {
  display: block;
}

.icon-left,
.icon-right {
  display: flex;
  justify-content: start;
  align-items: center;
  align-content: center;
  gap: 8px;
}

.panel > p {
  margin: 0;
  padding: 16px 0;
}

img.media-image {
  object-fit: cover;
  border-radius: 1%;
  display: block;
  width: 100%;
  max-width: 1024px;
}

table.table.habits td {
  vertical-align: top;
}

table.thumb th:first-child,
table.thumb td:first-child {
  width: 40px;
}

table.table th.date,
table.table td.date,
table.date th:last-child,
table.date td:last-child {
  width: 180px;
}

table td.thumb {
  width: 80px;
}

span.thumb,
img.thumb,
table td.thumb img,
table td:first-child img {
  max-height:48px;
  max-width: 64px;
  border-radius: 8px;
}

span.thumb {
  border: #CCC 1px dashed;
  white-space: wrap !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 13px;
  padding: 8px;
}

tr.working {
  opacity: 0.5;
  user-input: none;
}

div.line-item.status-0,
table tr.status-0 {
  opacity: 0.5;
}

div.line-item.status--1,
table tr.status--1 {
  opacity: 0.25;
  text-decoration: line-through;
}

table tr.header td strong {
  padding-top: 12px;
  display: inline-block;
  font-size: 25px;
  font-weight: normal;
}

table.table {
  border-spacing: 0;
  padding: 0;
  width: 100%;
}

table.table thead {
  font-weight: bold;
  color: black;
}

table.table th {
}

table.table td,
table.table th {
  border-bottom: var(--border);
  padding: 10px 0;
  text-align: left;
}

table.table th:last-child,
table.table td:last-child {
  text-align: right;
}

.date-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

ul, li {
  display: block;
  list-style: none;
}

ul {
  padding: 0;
}

#login {
  width: 500px;
  margin: 64px auto 24px auto;
  background-color: white;
  border: var(--border);
  border-radius: 12px;
  text-align: center;
  padding: 32px 0;
  box-shadow: 0 0 64px 0 rgba(0, 0, 0, 0.1);
}

.fader {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  transition-delay: 0ms;
  opacity: 1.0;
}

.hidden {
  display: none;  
}


#login .form {
  display: inline-block;
  text-align: left;
  margin: auto;
}

.html-editor {
  display: block;
  min-width: 50vw;
}

.pi-modal-body .rsw-html,
.pi-modal-body .rsw-html textarea {
  min-height: 300px;
  font-size: 13px;
}

.pi-modal-dialog-frame.success .pi-modal-dialog-icon svg {
    color: green;
}

.pi-modal-dialog-frame.error .pi-modal-dialog-icon svg {
    color: maroon;
}

.line-item .flex .details span.inline-edit {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}

.line-item .flex .details span.inline-edit textarea {
  width: 600px;
  min-height: 100px;
}

.form .form-notes {
  font-size: 13px;
  padding: 6px 0;
}

.form .form-group .form-error {
  padding-bottom: 8px;
  color: var(--error-text-color);
}

.form .form-error.large {
  padding: 16px 0;
  text-align: right;
}

.form .form-group .form-error:empty {
  display: none;
}

.form .form-group {
  display: block;
  padding-bottom: 16px;
}

.form .form-group .notes {
  font-size: 11px;
  padding-top: 5px;
  color: var(--notes-color);
}

.form .form-group .notes:empty {
  display: none;
}

.form .form-group.double {
  display: flex;
  gap: 12px;
  width: 100%;
}

.form .form-group.double > div {
  flex-grow: 1;
}

.form .form-group.double > div:first-child {
  padding-left: 0;
}

.form .form-group.triple {
  display: flex;
  gap: 12px;
  width: 100%;
}

.form .form-group.triple > div {
  flex-grow: 1;
  width: 33%;
}

.form .form-group.triple > div:first-child {
  padding-left: 0;
}

.form .form-group.triple > div select {
  width: 100%;
}

.form textarea.inline {
  width: 500px;
  min-width: 200px;
  max-width: 800px;
  height: 50px;
  min-height: 24px;
}

.form p textarea.inline {
}

select:disabled,
textarea:disabled,
input:disabled {
  color: #AAA;
}

.form textarea,
.form input {
  width: 100%;
  box-sizing: border-box;
}

.form input[type="submit"],
.form input[type="radio"],
.form input[type="color"],
.form input[type="checkbox"] {
  width: unset;
}

.form .fields > .form-group:last-child {
  padding-bottom: 0;
}

.form .form-group label {
  font-size: 14px;
}

.form .form-group label:first-child {
  display: block;
  padding: 8px 0;
}

.form .form-group label + input {
  display: block;
}

.empty-box {
  text-align:center;
  display: block;
  padding: 48px 24px;
  border: var(--border);
  border-radius: 12px;
  margin-bottom: 32px;
}

.error-box {
  color: maroon;
}

.form-error {
  color: maroon;
  margin-bottom: 12px;
}

.form-error:empty {
  display: none;
}


#login .form .form-group > label + input {
  width: 350px;
  margin-bottom: 16px;
}

.form .buttons {
  padding-top: 10px;
}

.form .form-buttons {
  padding-top: 24px;
  text-align: right;
}

.form .form-buttons.flex > span:first-child {
  margin-right: auto;
  display: inline-block;
}

.form .buttons #login-button {
  width: 128px;
}

.form .form-buttons button {
  min-width: 100px;
}

img.profile {
  border-radius: 100%;
}

img.size128 {
  object-fit: cover;
  width: 128px;
  height: 128px;
}

img.size64 {
  object-fit: cover;
  width: 64px;
  height: 64px;
}

img.size32 {
  object-fit: cover;
  width: 32px;
  height: 32px;
}

img.size24 {
  object-fit: cover;
  width: 24px;
  height: 24px;
}

.long-filter {
  width: 100%;
}

.long-filter .filter-panel input#filter-term {
  width: 100%;
}

.column-content > .column:first-child {
  width: 150px;
  min-width: 150px;
}

.center {
  text-align: center;
}

div.sub-menu {
  position: fixed;
  left: 224px;
  top: 104px;
  width: inherit;
}

div.sub-menu > .menu-heading {
  display: block;
  text-align: center;
  margin-bottom: 24px;
}

ul.sub-menu {
  width: 150px;
  display: block;
  padding: 0;
  margin-top: 9px;
}

ul.sub-menu > li {
  display: block;
  padding: 0;
  border-bottom: #CCC 1px solid;
  cursor: pointer;
}

ul.sub-menu > li > a {
  display: block;
  font-size: 13px;
  padding: 5px 8px;
  margin: 4px 0;
  border-radius: 5px;
  cursor: pointer;
}

ul.sub-menu > li.selected > a {
  font-weight: bold;
  background-color: #0000001A;
}

ul.sub-menu > li > a:hover {
  color: maroon;
}

div.sub-sub-menu {
  display: flex;
  gap: 16px;
}

div.sub-sub-menu > span {
  padding-bottom: 8px;
  border-bottom: transparent 3px solid;
}

div.sub-sub-menu > span.selected {
  border-bottom-color: black;
}

.pad {
  padding-left: 32px;
  padding-bottom: 64px;
}

ul > li > a.has-icon {
  justify-content: start;
  align-items: center;
  display: flex;
  gap: 10px;
}

span.initials {
  border-radius: 100%;
  background-color: #666;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  font-weight: bold;
}

.full-screen {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  
  opacity: 0.0;
  display: block;
  position: fixed;
  z-index: 299;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  text-align: center;
  background-color: black;
}

.full-screen.view {
  opacity: 1.0;
}

.form-flex,
.flex {
  display: flex;
  gap: 12px;
}

.form-flex {
  align-content: flex-start;
  align-items: flex-start;
}

.form-flex .inner-drop-target > div {
  line-height: 0;
}

.form-flex > * {
  flex-grow: 10;
}

.form-flex > *:first-child {
    flex-grow: 1;
  padding-top: 24px;
}

.date-search {
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
  margin-bottom: 16px;
}

h2 + .date-search {
  margin-top: 12px;
}

.flex-panel {
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
}

.draft {
  opacity: 0.35;
}

.drop-target.video-dropper,
.drop-target.image-dropper {
  cursor: pointer;
  padding: 0;
  width: 128px;
  height: 128px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.drop-target.video-dropper > .drag-over,
.drop-target.video-dropper > .inner-drop-target,
.drop-target.image-dropper > .drag-over,
.drop-target.image-dropper > .inner-drop-target {
  flex-grow: 2;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.drop-target.video-dropper > .inner-drop-target > video {
  width: 128px;
  height: 128px;
  background-color: black;
  object-fit: cover;
}

.top8 {
  margin-top: 8px;
}

.top12 {
  margin-top: 12px;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.top32 {
  margin-top: 32px;
}

.top64 {
  margin-top: 64px;
}

.bottom10 {
  margin-bottom: 10px;
}

.bottom16 {
  margin-bottom: 16px;
}

.bottom24 {
  margin-bottom: 24px;
}

.bottom32 {
  margin-bottom: 32px;
}

.bottom64 {
  margin-bottom: 64px;
}

.bottom128 {
  margin-bottom: 128px;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
  
}

.double-spaced > div {
  margin-bottom: 5px;
  display: block;
}

.h {
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: var(--border);
}

p.user-content {
  position: relative;
  padding: 12px;
  line-height: 1.3em;
  margin: 0 0 24px 0;
  border-radius: 12px;
  background-color: #07667322;
}

.habit-name {
  width: 220px;
}

.search-date {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 16px;
}

.nav, .with-button {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: start;
  gap: 8px;
}

.nav.with-button > span:last-child {
  font-weight: normal;
  font-size: 15px;
}

.nav.with-buttons {
  gap: 24px;
}

.nav.with-buttons > span:first-child {
  margin-right: auto;
}

.nav.with-buttons > span:not(:first-child) {
  font-size: 14px;
  font-weight: normal;
}

.nav .push {
}

.nav .push > a {
  display: inline-block;
  padding: 6px 10px;
  border-radius: 5px;
  border: #CCC 1px solid;
  font-size: 12px !important;
  color: var(--link-color);
  text-decoration: none;
}

.nav .push > a:hover {
  background-color: #CCC;
}

.top-right {
  margin-left: auto;
  font-size: 15px;
  font-weight: normal;
}

p.rich {}

span.html-line,
p.rich > span {
  display: block;
  padding: 3px 0;
}

a.delete {
  color: maroon;
  display: inline-block;
  padding: 5px 8px;
  border-radius: 5px;
  border: maroon 1px solid;
  font-size: 12px;
}

a.delete:hover {
  color: white;
  background-color: maroon;
  text-decoration: none;
}

.with-button > button:last-child,
.with-button > span:last-child {
  margin-left: auto;
  display: inline-block;
}

.nav > a {
  font-size: 0.9rem;
  color: var(--text-color);
}

.pi-modal-container.dialog>.pi-modal-body .workout-form h2 {
  padding-left: 0;
}

.exercise-form-dialog,
.exercise-form-dialog > div {
  max-width: 600px;
}

span.add-new, span a.add-new {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 10px;
  border-radius: 5px;
  border: #CCC 1px solid;
  font-size: 14px;
  font-weight: normal;
}

.refresh-button,
span.add-new-button > a {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  border: #CCC 1px solid;
  gap: 8px;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}

.refresh-button > span.spinner {
  display: none;
}

.refresh-button.refreshing > span.spinner {
  display: flex;
}

.refresh-button.refreshing-1 > span.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 16px;
  height: 16px;
}

.refresh-button.refreshing-1 > span.spinner > label {
  border: red 1px solid;
  border-right-color: transparent !important;
  width: 100%;
  height: 16px;
  border-radius: 100px;
  animation: spinner 250ms linear infinite;
}

.nav > span.bullet {
  font-size: 12px;
  display: inline-block;
}

p.p {
  margin-bottom: 32px;
}

p.p800 {
  margin-bottom: 32px;
  max-width: 800px;
  display: block;
}

.constrain800,
.constrain { 
  max-width: 800px;
}

.constrain600 {
  max-width: 600px;
}

.constrain1024 {
  max-width: 1024px;
}

.constrain video {
  max-width: 800px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
}

pre {
  font-size: 13px;
  padding: 12px;
  border-radius: 10px;
  background-color: #CCC;
  text-overflow: inherit;
  white-space: break-spaces;
}

pre {
  display: none;
}

.show-debug pre {
  display: block;
}

.un-sort {
  
}

.sorting .cooking-direction {
  display: flex !important;
}

.sorting .cooking-direction {
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  border: #CCC 1px solid;
  border-radius: 12px;
  padding: 12px;
}

.sorting .cooking-direction > *:last-child {
  width: 100px !important;
  min-width: 100px !important;
}

.sorting .cooking-direction .text-editable {
  display: inline-block;
  overflow: hidden;
  margin-right: 24px !important;
}

.sort-icon {
  border: #CCC 1px solid;
  border-radius: 8px;
  display: inline-block;
  font-size: 12px;
}

.sort-icon:hover {
  background-color: #FF000011;
  color: maroon;
}

.sort-icon:hover > a {
  color: maroon !important;
}

.sort-icon > a:hover {
  text-decoration: none;
  color: black;
}

.sorting .sort-icon {
  background-color: #00000011;
}

.meal-sub-menu {
  margin-bottom: 32px;
}

.edit-cooking-direction {
  border: #CCC 1px solid;
  padding: 16px;
  margin: 16px 0;
  background-color: #FF000006;
}

.sort-icon > * {
  gap: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.sorting .cooking-direction .text-editable:hover,
.sorting .cooking-direction .text-editable,
.sorting .cooking-direction .text-editable p {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  background-color: transparent;
}

.sorting .cooking-direction img {
  max-height: 64px;
}

.sorting .un-sort {
  display: none;
}

.sortable-item {
  
}

.sortable-item .grip {
  cursor: pointer;
  width: 18px;
  height: 16px;
  transition-property: width;
  transition-duration: 250ms;
  transition-timing-function: linear;
  overflow: hidden;
  display: inline-block;
}

.sortable-item td:last-child > .grip {
  margin-left: 8px;
}

.sortable-hidden .sortable-item .grip,
.hidden > .sortable-item .grip,
.sortable-item .grip.hidden {
  cursor: pointer;
  width: 0 !important;
}

.items {
  display: flex;
  align-items: center;
}

.completed.time.wakeup > span:first-child {
  display: none;
}

.time {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-transform: uppercase;
  
  font-size: 40px;
  font-weight: normal;
  text-shadow: 1px 1px 10px #00000066;
  padding-bottom: 16px;
}

.time > strong {
  font-weight: normal;
}

.wrap-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: start;
}

.wrap-grid > div {
  border: var(--border);
  border-radius: 12px;
  padding: 0;
}

.wrap-grid > div > a.media-item {
  display: inline-block;
  padding: 8px;
  line-height: 0;
}

.media-grid {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: #CCC 1px dashed;
}

.media-grid:empty {
  display: none;
}

.wrap-grid > div > a.media-item > img,
img.media-thumb {
  width: 128px;
  height: 128px;
  max-height: 128px;
  min-height: 128px;
  object-fit: cover;
  border-radius: 8px;
}

.no-margin {
    margin: 0;
}

.grid {
  display: grid;
  gap: 12px;
  grid-template-columns: minmax(10px, 128px) 128px 128px 128px;
  justify-content: space-between;
}

.grid.media {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
}

.grid.media > div {
  width: 256px;
  min-width: 256px;
  border-radius: 8px;
  position: relative;
  padding: 0;
  overflow: clip;
}

.grid.media > div.drop-target,
.grid.media > div.drop-target > div.inner-drop-target,
.grid.media > div.drop-target > div.drag-over {
  padding: 0;
  cursor: pointer;
}

.grid.media > div.drop-target > div.drag-over {
  opacity: 0.6;
}

.grid.media > div.drop-target > div.drag-over a {
  color: black;
}

.grid.media > div > div > div.thumb {
  position: relative;
}

.grid.media > div > div > div.thumb > div,
.grid.media > div > div > div.thumb > span {
  top: 8px;
  left: 8px;
  color: white;
  position: absolute;
}

.grid.media > div > div > div.thumb a.title-edit,
.grid.media > div > div > div.thumb > * > * > a {
  color: white;
}

.grid.media > div > div > label {
  display: block;
  padding: 12px !important;
}

.grid.media > div > div > label > * {
  display: block;
  padding-bottom: 8px;
}

.grid > div img.media {
  width: 256px;
  object-fit: cover;
  overflow: hidden;
}

.grid > div {
  border: var(--border);
}

.metrics {
  flex-grow: 4;
  width: 100%;
}

.drag-over {
  background-color: red;
}

.hover {
    background-color: #DDD;
}

.drag-over {
  background-color: #FEE;
}

.has-files {
  background-color: #FFE;  
}

.drop-target {
  border-radius: 8px;
  border: var(--border);
}

.drop-target > * {
  padding: 24px;
  border-radius: 8px;
}

.image-gallery-item,
.image-upload-preview,
.image128 { 
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 8px;
}

.directions-image-dropper img.image-upload-preview {
  width: 100%;
  max-width: 100%;
  max-height: 256px;
  height: unset;
}

.directions-image-dropper label.spinner {
  border-color: white !important;
  border-right-color: transparent !important;
  animation-duration: 350ms;
}

.flex-label {
  display: flex;
  gap: 12px;
  align-items: center;
  align-content: center;
}

.small {
  font-size: 11px;
}

.medium {
  font-size: 13px;
}

textarea.small {
  font-size: unset;
  min-height: 90px;
}

textarea.medium {
  font-size: unset;
  min-height: 120px;
}

textarea.large {
  font-size: unset;
  min-height: 220px;
}

.wrap-grid > .tile {
  cursor: pointer;
  border-radius: 8px;
  background-color: #888;
  color: white;
  padding: 24px;
  text-align: center;
  min-width: 128px;
  max-width: 220px;
  width: 178px;
  text-decoration: none;
}

.wrap-grid > .tile:hover {
  background-color: #333;
}

.wrap-grid > .tile > h3 { 
  margin-bottom: 8px;
}

.wrap-grid > .tile > svg {
  
}

.wrap-grid > .tile > p {
  margin-bottom: 0;
}

.directions-image-dropper .center-uploading {
  background-color: var(--def-red);
  color: white;
  opacity: 0.5;
  display: none;
  padding: 16px 24px !important;
  border-radius: 8px;
}

.directions-image-dropper.uploading .center-uploading {
  display: inline-block;
}

.drop-target.directions-image-dropper > * {
}

.activity,
.animator,
.spinner {
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-delay: 0ms;
  animation-iteration-count: infinite;
}

label.spinner {
  display: inline-block;
  min-width: 16px;
  min-height: 16px;
  border: var(--def-red) 4px solid !important;
  border-right-color: transparent !important;
  border-radius: 100%;
}

.drop-area {
  position: relative;
}

.drop-target.icon-upload > * {
  padding: 16px;
}

.center-uploading {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: inherit;
  transform: translate(-50%, -50%);
}

.uploading .spinner,
.working .spinner {
  animation-name: spinner;
}

.working-only {
  display: none;
}


.uploading .working-only,
.working .working-only {
  display: unset;
}

.uploading .working-only {
  display: inline-block;
  border: red 3px solid;
  width: 32px;
  height: 32px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}