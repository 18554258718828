div.media-gallery {
    
}

div.media-gallery * {
    box-sizing: border-box;
    max-width: 100%;
}

div.gallery-items {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

div.media-gallery > div.wrap-grid {
    margin-top: 12px;
    padding-top: 12px;
    line-height: 0;
}

div.media-gallery > div img {
    object-fit: cover;
    border-radius: 8px;
    box-sizing: border-box;
}

div.media-gallery > div.wrap-grid img {
    
}

div.media-gallery > div.wrap-grid > .media-item > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: clip;
    border-radius: 8px;
}

.constrain div.media-gallery > div img {
    max-width: 100%;
}

div.thumb.media-item,
div.media-gallery > div > div.media-item {
    overflow: clip;
    padding: 7px;
    border: var(--border);
    border-radius: 10px;
    display: inline-block;
}

div.media-gallery div.media-item > .captions > .items {
    display: block;
}

div.media-gallery div.media-item > .captions > .items > span {
    display: block;    
}

div.media-gallery div.media-item > .captions > .items .date-info {
    display: flex;
}

div.media-gallery .wrap-grid div.media-item > .captions > .items .date-info {
    display: block;
    padding-top: 4px;
    font-size: 12px;
}

div.media-gallery .wrap-grid div.media-item > .captions > .items .date-info > span {
    display: block;
    margin-bottom: 4px;
}

.pi-modal-content.user-media-dialog .media-form {
    margin-top: 5px;
}

.media-view > .drop-target.image-dropper {
    width: 100%;
    height: unset;
    border: none;
}

.media-view > .drop-target .inner-drop-target .video-dropper {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: var(--border-color) 2px dashed;
    border-radius: 12px;
}

.media-view > .drop-target.image-dropper > .inner-drop-target {
    padding: 0;
    margin: 0;
    width: 100%;
}

.media-view > .drop-target.image-dropper > div.inner-drop-target > img {
    border-radius: 16px;
}

div.gallery-items > .media-gallery > .wrap-grid > .media-item iframe,
div.gallery-items > .media-gallery > .wrap-grid > .media-item img {
    object-fit: cover;
}

div.gallery-items > .media-gallery > .wrap-grid > .media-item iframe {
    border-radius: 8px;
}

div.gallery-items > .media-gallery > .wrap-grid > .media-item img {
    
}

div.media-gallery > div.line-items > div.media-item {
    margin-bottom: 32px;
}

div.media-gallery > div.line-items > div.media-item > span.captions,
div.media-gallery > div.line-items > div.media-item > span.captions > * {
    display: block;
}

div.media-gallery > div.line-items > div.media-item > span.captions > span {
    padding-top: 8px;
    padding-bottom: 8px;
}

div.media-gallery > div.line-items > div.media-item > span.captions > span.items {
    justify-content: space-between;
}

div.media-gallery > div.line-items > div.media-item > span.captions > span.items > span > span,
div.media-gallery > div.line-items > div.media-item > span.captions > span.items > span > strong {
    display: block;
}

div.media-gallery > div.line-items > div.media-item > span.captions > span.items > span > strong {
    padding-bottom: 8px;
}

div.media-gallery > div.line-items > div.media-item > span.captions > span.items > span:last-child {
    margin-left: auto;
}

.constrain,
.gallery-uploader {
    box-sizing: border-box;
}

.gallery-uploader.user-habits > .drop-target {
    border-width: 0;
    box-sizing: border-box;
}

.gallery-uploader.user-habits > .drop-target > .inner-drop-target {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.gallery-uploader.user-habits > .drop-target > .inner-drop-target .media-gallery {
    box-sizing: border-box;
}